.location-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

@media (max-width: 600px) {

	/* For small screens */
	.location-list {
		flex-direction: column;
	}
}

@media (min-width: 601px) and (max-width: 1024px) {

	/* For medium screens */
	.location-list>div {
		flex-basis: calc(50% - 10px);
		/* 2 items per row */
	}
}

@media (min-width: 1025px) {

	/* For large screens */
	.location-list>div {
		flex-basis: calc(33.333% - 10px);
		/* 3 items per row */
	}
}